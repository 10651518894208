/* eslint-disable */
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
// eslint-disable-next-line
import {
	cyan,
	grey,
	indigo,
	lightBlue,
	orange,
	pink
} from '@material-ui/core/colors'

let MyTheme = createMuiTheme({
	palette: {
		primary: {
			main: orange[700]
		},
		secondary: {
			main: grey[600]
			// main: pink[500]
		},
		text: {
			primary: grey[700],
			secondary: grey[600],
			disabled: grey[400]
		}
	},
	//type: 'dark', //this is not working
	typography: {
		// Tell Material-UI what's the font-size on the html element is.
		fontSize: 12
		// fontWeightMedium: 200
	}
})

MyTheme = responsiveFontSizes(MyTheme)

export default MyTheme
